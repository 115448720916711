<template>
  <v-main>
    <v-container class="pt-0">
      <div class="stick-nav px-3">
        <v-row class="mt-5 mb-16">
          <v-col cols="12" sm="8" md="8">
            <h1 left class="text-left">Settings</h1>
          </v-col>
          <v-col class="done" cols="12" sm="4">
            <v-btn
              @click.stop="save"
              class="mx-2"
              width="180px"
              height="45px"
              depressed
              dark
            >
              <v-icon class="mr-2">mdi-content-save</v-icon>

              <div class="font-weight-black">Save</div>
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <!-- new icon -->

      <v-snackbar
        class="mb-14"
        :timeout="2000"
        color="#00000"
        v-model="alert"
        dark
      >
        <div style="font-weight: bold; letter-spacing: 2px" class="text-center">
          {{ alertdata }}
        </div>

        <template v-slot:actions>
          <v-btn color="indigo" @click="alert = false"> Close </v-btn>
        </template>
      </v-snackbar>

      <v-card
        style="border: 1px solid grey"
        flat
        rounded="80"
        outlined
        class="pa-5 my-5 carecard icons_card"
      >
        <v-card-title class="text-overline" style="font-weight: bold">
          ICON
        </v-card-title>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              class="text-wrap text-left text-h8 font-weight-regular"
            >
              Select the icon that will appear in your store:
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-row class="px-5">
          <v-radio-group v-model="selected_icon" row @change="iconselect()">
            <div
              v-for="available_icon in available_icons"
              :key="available_icon.name"
            >
              <v-card
                style="border: 1px solid grey"
                outlined
                flat
                class="ma-3 pa-5"
              >
                <v-img
                  contain
                  class="ma-4"
                  width="60"
                  height="60"
                  :src="'/' + available_icon.filename"
                ></v-img>
                <v-radio color="cyan" :value="available_icon.name"></v-radio
              ></v-card>
            </div>
          </v-radio-group>
        </v-row>
      </v-card>

      <!-- new label -->
      <v-row>
        <v-col cols="12" lg="6" >
          <v-card
            style="border: 1px solid grey"
            rounded="80"
            outlined
            class="pa-5 my-5 carecard icons_card"
            height="90%"
            
          >
            <v-card-title class="text-overline" style="font-weight: bold">
              LABEL
            </v-card-title>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="text-wrap text-left text-h8 font-weight-regular"
                >
                  Select the label and that will appear in your store. The label
                  can be entered in any language.
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-card-text>
              <v-row justify="left">
                <v-col cols="12" md="6">
                  <v-text-field
                    class="my-5"
                    outlined
                    dense
                    color="#F1B24A"
                    :rules="rules"
                    hide-details="auto"
                    placeholder="Size Guide"
                    :value="sizetext"
                    @input="up($event, 'sizetext')"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text> 
          </v-card>
          </v-col>
          
          <v-col cols="12" lg="6" fill-height>
          
          <!-- new font family -->

      <v-card
        style="border: 1px solid grey"
        rounded="80"
        outlined
        class="pa-5 my-5 carecard icons_card"
        v-if="size_popup_version"
        height="90%"
      >
        <v-card-title class="text-overline" style="font-weight: bold">
          FONT STYLE
        </v-card-title>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              class="text-wrap text-left text-h8 font-weight-regular"
            >
              Select the font style that you wish to have in your size charts.
              You can use our default font (Montserrat) or the same font as in
              your Shopify theme.
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-radio-group v-model="popup_setting_font" row class="pl-4">
          <v-radio
            color="cyan"
            value="default"
            label="SmartSize style"
          ></v-radio>
          <v-radio color="cyan" value="inherit" label="Theme style"></v-radio>
        </v-radio-group>
      </v-card>
          
          
          </v-col
      ></v-row>

      <v-card
            style="border: 1px solid grey"
            rounded="80"
            outlined
            class="pa-5 my-5 carecard icons_card"
            v-if="size_popup_version"
          >
            <v-card-title class="text-overline" style="font-weight: bold">
              SIZE BUTTON PLACEMENT
            </v-card-title>

            <v-radio-group v-model="popup_setting_size_button_display" row class="pl-4">
              <v-radio color="cyan" value="floating" label="Floating Button" />
              <v-radio color="cyan" value="inline" label="Inline Button" />
            </v-radio-group>

            <v-list
              v-if="!isFloatingButton"
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-wrap text-left text-h8 font-weight-regular my-4"
                  >
                    <p>OPTION A</p><strong>Add a block in your Shopify theme</strong>  This is the simplest option but it is only supported by selected themes using Shopify Online 2.0
                    <a
                      href="https://smartsize.zohodesk.eu/portal/en/kb/articles/how-can-i-add-a-smartsize-block-in-my-shopify-theme"
                      target="_blank"
                      >See details here
                      <v-icon class="mx-1" small>mdi-open-in-new</v-icon></a
                    >
                  </v-list-item-title>
                  <v-list-item-title
                    class="text-wrap text-left text-h8 font-weight-regular mt-4"
                  >
                    <p>OPTION B</p><strong>Enter a CSS class.</strong> This option is a bit more complex. In the box below, enter the CSS class where you wish to display
                    your size chart button. The button will appear right below that class. Make sure there are no typos.
                    <a
                      href="https://smartsize.zohodesk.eu/portal/en/kb/articles/button-placement-via-classname"
                      target="_blank"
                      >See details here
                      <v-icon class="mx-1" small>mdi-open-in-new</v-icon></a
                    >
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            <v-card-text>
              <v-row justify="left">
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    dense
                    color="#F1B24A"
                    hide-details="auto"
                    placeholder="product-form__input"
                    v-model="popup_setting_css_selector"
                    :disabled="isFloatingButton"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text> 
          </v-list>

          </v-card>
          
      

      <!-- new colors -->

      <v-card
        style="border: 1px solid grey"
        flat
        rounded="50"
        outlined
        class="pa-5 my-5 carecard icons_card"
      >
        <v-card-title class="text-overline" style="font-weight: bold">
          COLORS
        </v-card-title>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              class="text-wrap text-left text-h8 font-weight-regular"
            >
              Choose the colors for your size chart to match your store.
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-row>
          <v-col cols="12" lg="6">
            <v-card flat outlined class="pa-10">
              <table class="switcher" cellspacing="0">
                <tr :style="{ background: header_color, color: header_text }">
                  <td
                    :style="{ background: header_color, color: header_text }"
                  ></td>
                  <td
                    v-bind:key="`header${index}`"
                    v-for="(item, index) in colHeader"
                    :style="{ background: header_color }"
                  >
                    {{ item }}
                  </td>
                </tr>
                <tr
                  v-bind:key="`row${rIndex}`"
                  v-for="(item, rIndex) in rowHeader"
                  :style="{
                    background: rIndex % 2 !== 0 ? zebra_lines : '#fff',
                  }"
                >
                  <td
                    :style="{
                      background: rIndex % 2 !== 0 ? zebra_lines : '#fff',
                    }"
                  >
                    {{ item.chart_item_name }}
                    <!--  measurementItems.find(
                    (measurementItem) => measurementItem.id == item.chart_itemId
                  ).measure -->
                  </td>
                  <td
                    v-bind:key="`cell-${rIndex}-${cIndex}`"
                    :id="`cell-${rIndex}-${cIndex}`"
                    v-for="(cell, cIndex) in item.chartData"
                  >
                    <span
                      class="rounded-circle"
                      :style="{
                        background: focusedCells.includes(
                          `cell-${rIndex}-${cIndex}`
                        )
                          ? focus
                          : 'transparent',
                      }"
                      @click="toggleCell(`cell-${rIndex}-${cIndex}`)"
                    >
                      {{ cell }}
                    </span>
                  </td>
                </tr>
              </table>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6">
            <v-card flat class="pr-10">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">
                      Header Background
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon class="my-3">
                    <v-swatches
                      show-fallback
                      fallback-input-type="color"
                      v-model="header_color"
                      popover-x="right"
                    >
                      <input
                        slot="trigger"
                        class="rounded-circle color-selector"
                        readonly
                        :style="{ background: header_color }"
                      />
                    </v-swatches>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">
                      Header Text
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon class="my-3">
                    <v-swatches
                      show-fallback
                      fallback-input-type="color"
                      v-model="header_text"
                      popover-x="right"
                    >
                      <input
                        slot="trigger"
                        class="rounded-circle color-selector"
                        readonly
                        :style="{ background: header_text }"
                      />
                    </v-swatches>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">
                      Zebra Lines
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon class="my-3">
                    <v-swatches
                      show-fallback
                      fallback-input-type="color"
                      v-model="zebra_lines"
                      popover-x="right"
                    >
                      <input
                        slot="trigger"
                        class="rounded-circle color-selector"
                        readonly
                        :style="{ background: zebra_lines }"
                      />
                    </v-swatches>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">
                      Focus
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon class="my-3">
                    <v-swatches
                      show-fallback
                      fallback-input-type="color"
                      v-model="focus"
                      popover-x="right"
                    >
                      <input
                        slot="trigger"
                        class="rounded-circle color-selector"
                        readonly
                        :style="{ background: focus }"
                      />
                    </v-swatches>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <!-- new border -->

      <v-card
        style="border: 1px solid grey"
        flat
        rounded="80"
        outlined
        class="pa-5 my-5 carecard icons_card"
        v-if="size_popup_version"
      >
        <v-card-title class="text-overline" style="font-weight: bold">
          SIZE CHART BORDER
        </v-card-title>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              class="text-wrap text-left text-h8 font-weight-regular"
            >
              Select the border that will appear in your size chart:
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-row class="pa-5">
          <v-radio-group v-model="popup_setting_table_borders" row>
            <div
              v-for="available_border in available_border"
              :key="available_border.name"
            >
              <v-card
                style="border: 1px solid grey"
                outlined
                flat
                class="ma-3 pa-5"
              >
                <v-img
                  contain
                  class="ma-4"
                  width="60"
                  height="60"
                  :src="'/' + available_border.filename"
                ></v-img>
                <v-radio color="cyan" :value="available_border.name"></v-radio
              ></v-card>
            </div>
          </v-radio-group>
        </v-row>
      </v-card>

      <!-- vertical position -->

      <v-row>
        <v-col cols="12" lg="6">
          <v-card
            style="border: 1px solid grey"
            flat
            rounded="80"
            outlined
            height="750px"
            class="pa-5 mb-16 carecard icons_card"
          >
            <v-card-title class="text-overline" style="font-weight: bold">
              VERTICAL POSITION
            </v-card-title>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="text-wrap text-left text-h8 font-weight-regular"
                >
                  <p class="mt-5 mb-16">
                    Available for themes using Online Store 2.0 with Add Block
                    option.
                  </p>
                  <v-img
                    contain
                    height="300"
                    class="ma-6 pa-10"
                    src="/alignment_horizontal.png"
                  ></v-img>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-card-text>
              <v-row class="d-flex justify-center my-5">
                <v-dialog
                  v-model="dialog_position"
                  persistent
                  min-heigth="1000"
                  max-width="1000"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-3"
                      depressed
                      dark
                      color="black"
                      min-width="200px"
                      height="40px"
                      v-bind="attrs"
                      v-on="on"
                    >
                      change vertical position
                    </v-btn>
                  </template>
                  <v-card color="#ffffff">
                    <v-card-title class="mb-5 text-h5">
                      How to change the vertical position of your label
                    </v-card-title>
                    <v-card-text>
                      Available for themes using Online Store 2.0 with Add Block
                      option.
                    </v-card-text>

                    <v-img
                      class="mx-6 my-5"
                      height="450"
                      contain
                      src="/change_vertical_position.gif"
                    ></v-img>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn flat text @click="dialog_position = false">
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6">
          <v-card
            style="border: 1px solid grey"
            flat
            rounded="50"
            outlined
            class="pa-5 mb-16 carecard icons_card"
            height="750px"
          >
            <v-card-title class="text-overline" style="font-weight: bold">
              HORIZONTAL POSITION
            </v-card-title>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="text-wrap text-left text-h8 font-weight-regular"
                >
                  <p class="mt-5 mb-16">
                    Available for themes using Online Store 2.0.
                  </p>
                  <v-img
                    contain
                    height="300"
                    class="ma-6 pa-10"
                    src="/alignment_horizontal.png"
                  ></v-img>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-card-text>
              <v-row class="d-flex justify-center my-5">
                <v-dialog
                  v-model="dialog_position_horizontal"
                  persistent
                  min-heigth="1000"
                  max-width="1000"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-3"
                      depressed
                      dark
                      color="black"
                      min-width="200px"
                      height="40px"
                      v-bind="attrs"
                      v-on="on"
                    >
                      change horizontal position
                    </v-btn>
                  </template>
                  <v-card color="#ffffff">
                    <v-card-title class="mb-5 text-h5">
                      How to change the horizontal position of your label
                    </v-card-title>
                    <v-card-text
                      >Go to customize your Shopify Theme and open the product
                      page. Click on 'Product Information' and select 'Add
                      Block'. Choose SmartSize. Now you can drag the block to
                      your desired position.
                    </v-card-text>

                    <v-img
                      class="mx-6 my-5"
                      height="450"
                      contain
                      src="/change_horizontal_position.gif"
                    ></v-img>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        flat
                        text
                        @click="dialog_position_horizontal = false"
                      >
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import VSwatches from "vue-swatches";

export default {
  data() {
    return {
      rules: [
        (value) => !!value || "Required.",
        (value) => (value && value.length <= 20) || "Max 20 characters",
      ],
      sizetext: "",
      caretext: "",
      care: "",
      size: "ruler.svg",
      dialog_position: false,
      dialog_position_horizontal: false,
      selected_icon: "Grey Ruler",
      popup_setting_font: "inherit",
      popup_setting_table_borders: "tunnel",
      popup_setting_css_selector: "",
      popup_setting_size_button_display: "floating",
      available_icons: [
        { name: "Ruler", filename: "ruler.svg" },
        { name: "Hanger", filename: "hanger.svg" },
        { name: "Table", filename: "arrows.svg" },
        { name: "Pink Table", filename: "arrows_pink.svg" },
        { name: "Pink Hanger", filename: "hanger_pink.svg" },
        { name: "Pink Ruler", filename: "ruler_pink.svg" },
        { name: "Grey Table", filename: "arrows_grey.svg" },
        { name: "Grey Hanger", filename: "hanger_grey.svg" },
        { name: "Grey Ruler", filename: "ruler_grey.svg" },
        { name: "Black Table", filename: "arrows_black.svg" },
        { name: "Black Hanger", filename: "hanger_black.svg" },
        { name: "Black Ruler", filename: "ruler_black.svg" },
        { name: "Brown Table", filename: "arrows_brown.svg" },
        { name: "Brown Hanger", filename: "hanger_brown.svg" },
        { name: "Brown Ruler", filename: "ruler_brown.svg" },
        { name: "No Icon", filename: "sizenone" },
      ],
      available_border: [
        { name: "tunnel", filename: "b_tunnel.svg" },
        { name: "lines", filename: "b_lines.svg" },
        { name: "grid", filename: "b_grid.svg" },
      ],
      careicons: ["carenone", "cloud", "leaf", "wash"],
      alert: false,
      alertdata: "",
      type: "success",

      colHeader: ["XS", "S", "M", "L"],
      rowHeader: [
        {
          chart_item_name: "Bust",
          chartData: ["32", "33", "35", "37"],
        },
        {
          chart_item_name: "Waist",
          chartData: ["25", "26", "28", "30"],
        },
        {
          chart_item_name: "Hips",
          chartData: ["34", "35", "37", "39"],
        },
      ],
      focusedCells: [],
    };
  },
  computed: {
    isFloatingButton() {
      return this.popup_setting_size_button_display == 'floating';
    },
    header_color: {
      get() {
        return this.$store.state.header_color;
      },
      set(value) {
        this.$store.commit("updateHeaderColor", value);
      },
    },
    header_text: {
      get() {
        return this.$store.state.header_text;
      },
      set(value) {
        this.$store.commit("updateHeaderText", value);
      },
    },
    focus: {
      get() {
        return this.$store.state.focus;
      },
      set(value) {
        this.$store.commit("updateFocus", value);
      },
    },
    zebra_lines: {
      get() {
        return this.$store.state.zebra_lines;
      },
      set(value) {
        this.$store.commit("updateZebraLines", value);
      },
    },
    size_popup_version() {
      return this.$store.getters.getShopProfile.size_popup_version;
    },
  },
  components: {
    VSwatches,
  },
  watch: {
    alert(val) {
      if (val) {
        setTimeout(() => {
          this.alert = false;
        }, 3000);
      }
    },
  },
  methods: {
    async iconload() {
      await this.$http
        .get(process.env.VUE_APP_SERVER_URL + "/api/icons", {
          params: { shop: localStorage.getItem("shop") },
        })
        .then((response) => (this.icon = response.data))
        .catch((error) => console.log(error));
      this.sizetext = this.icon.sizetext;
      this.caretext = this.icon.caretext;
      this.selected_icon = this.available_icons.find((selectedName) => {
        return selectedName.filename == this.icon.sizeicon;
      }).name;
      this.iconselect();
      this.popup_setting_font = this.icon.popup_setting_font;
      this.popup_setting_table_borders = this.icon.popup_setting_table_borders;
      this.popup_setting_css_selector = this.icon.popup_setting_css_selector;
      this.popup_setting_size_button_display = this.icon.popup_setting_size_button_display;
    },
    iconselect() {
      this.size = this.available_icons.find((selectedName) => {
        return selectedName.name == this.selected_icon;
      }).filename;
    },
    up(data, target) {
      this[target] = data;
    },
    async save() {
      await this.$http
        .post(process.env.VUE_APP_SERVER_URL + "/api/seticons", {
          shop: localStorage.getItem("shop"),
          sizetext: this.sizetext,
          caretext: this.caretext,
          care_icon: this.care,
          size_icon: this.size,
          popup_setting_font: this.popup_setting_font,
          popup_setting_table_borders: this.popup_setting_table_borders,
          popup_setting_css_selector: this.popup_setting_css_selector,
          popup_setting_size_button_display: this.popup_setting_size_button_display,
        })
        .then((response) => (this.returndata = response.data))
        .catch((error) => console.log(error));
      if (this.returndata == true) {
        this.trigger_alert("CHANGES HAVE BEEN SAVED", "success");
      }
      //this.$router.push("/");
    },
    trigger_alert(alertdata, type) {
      this.type = type;
      this.alertdata = alertdata;
      this.alert = true;
    },
    toggleCell(value) {
      if (this.focusedCells.includes(value)) {
        this.focusedCells = this.focusedCells.filter((item) => item != value);
      } else {
        this.focusedCells.push(value);
      }
    },
  },
  created() {
    this.iconload();
  },
};
</script>

<style lang="scss" scoped>
.stick-nav {
  position: sticky;
  top: 20px;
  background: #f3f5f7;
  z-index: 10;
  padding-top: 20px;
  top: 0px;
}
.done {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.color-selector {
  width: 50px;
  height: 50px;
  border: 1px solid #000;
}
.dividerelena {
  border-color: #4d5c75 !important;
  border-width: 1px !important;
  margin-top: 10px;
  border-radius: 20px;
}

.icons_card .v-card__actions {
  opacity: 1 !important;
  .v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #000000 !important;
    color: rgb(255, 255, 255) !important;
  }
}

.v-radio .v-icon {
  color: red;
  border-radius: 50%;
  background-image: radial-gradient(blue 50%, transparent 50%);
}
</style>
