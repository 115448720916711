<template>
  <!-- Icons -->
  <div class="background">
    <!-- <Colors></Colors> -->
    <Icons></Icons>
    <SmartSizeFooter></SmartSizeFooter>
  </div>
  <!-- Icoons -->
</template>

<script>
import Icons from "@/components/Icons/Icons.vue";
import SmartSizeFooter from "@/components/GlobalComponents/SmartSizeFooter.vue";
import VSwatches from "vue-swatches";
// import Colors from "@/components/Icons/Colors.vue";

export default {
  components: {
    Icons,
    SmartSizeFooter,
    VSwatches,
    // Colors,
  },
};
</script>

<style>
.background {
  background-color: #f3f5f7;
  min-height: 100vh;
}
</style>